import React, { useEffect, useState } from "react";
import { Colors } from "../Colors/Colors";
import { hapticsImpactLight, mapWithFallback } from "../../../lib/utils";

export interface TabItem {
  id: number;
  name: string;
}

interface TableTabsProps {
  tabItems: TabItem[];
  onTabSelect: (selectedTab: TabItem) => void;
  tabCustomStyle?: string;
  selectedTabStyle?: string;
  unselectedTabStyle?: string;
  renderTabText?: (item: TabItem) => string;
  resetTabs?: boolean;
  InicialselectedTab?: TabItem;
}

const TableTabs: React.FC<TableTabsProps> = ({
  tabItems,
  onTabSelect,
  tabCustomStyle = "",
  selectedTabStyle = "",
  unselectedTabStyle = "",
  renderTabText = (item) => item.name,
  resetTabs = false,
  InicialselectedTab = null,
}) => {
  const [selectedTab, setSelectedTab] = useState<TabItem | null>(
    InicialselectedTab,
  );

  useEffect(() => {
    if (resetTabs) {
      setSelectedTab(null);
    }
  }, [resetTabs]);

  useEffect(() => {
    setSelectedTab(InicialselectedTab);
  }, [InicialselectedTab]);

  const handleTabClick = (tab: TabItem) => {
    hapticsImpactLight();
    setSelectedTab(tab);
    onTabSelect(tab);
  };

  return (
    <div className="flex gap-2">
      {mapWithFallback(tabItems).map((tab, index) => {
        const isSelected = selectedTab?.name === tab.name;
        const isDefault = index === 0 && !selectedTab;

        const backgroundColor =
          isSelected || isDefault
            ? Colors.backgroundTertiary
            : Colors.backgroundSecundary;

        const tabStyle =
          isSelected || isDefault ? selectedTabStyle : unselectedTabStyle;

        return (
          <div
            key={tab.id}
            onClick={() => handleTabClick(tab)}
            className={`rounded-[50px] p-5 flex justify-center items-center text-white cursor-pointer
              bg-[${backgroundColor}] ${tabStyle} ${tabCustomStyle}`}
          >
            {renderTabText(tab)}
          </div>
        );
      })}
    </div>
  );
};

export default TableTabs;
